import React from "react"
import { Link } from "gatsby"
import { loadStripe } from "@stripe/stripe-js"
import "../components/layout.css"

const stripePromise = loadStripe(
  "pk_live_51Hj7BPJU3pXRkN5Sj9G4pmaY1J6unRwv2zsRG9TmyAkvjRJrQvNsypKO2JBoNeVZIAT9fdm7RI2PL84w13Xd88ER00eGRp7cNY"
)

const EarlyAccess = () => {
  const stripeCheckout = async (event, code) => {
    event.preventDefault()

    const stripe = await stripePromise

    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: code, quantity: 1 }],
      successUrl: `https://www.explot.io/success`,
      cancelUrl: `https://www.explot.io/early-access`,
    })

    if (error) {
      alert(`An error occured ${error.message}`)
    }
  }

  return (
    <div className="w-full min-h-screen gradient md:p-16">
      <div>
        <h1 className="text-center text-white">Get early access</h1>
        <p className="text-center text-white">
          Support Explot by buying early access and gain access to features as
          they are released.{" "}
        </p>
      </div>

      <div className="w-4/5 md:w-2/3 mx-auto shadow-custom p-4 rounded bg-white">
        <ul className="md:grid md:grid-cols-2 m-4 p-4 md:divide-x md:divide-gray-400 ">
          <li className="mx-2 px-4">
            <h2 className="text-center mb-1">
              <svg
                className="h-6 w-6 mr-2 inline"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#24B47E"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Visualization Pack
            </h2>
            <p className="text-sm py-4 h-28">
              Quickly visualize data with this package deal containing all the
              data visualizaion parts.
              <b>
                Contains all the features from the{" "}
                <a
                  className="underline"
                  target="_blank"
                  href="https://demo.explot.io/"
                >
                  demo
                </a>
                {". "}
              </b>
            </p>
            <p className="font-normal text-2xl text-center border-b-2 border-t-2 py-2">
              $ 49
              <code className="font-thin text-sm color-gray-400">
                {" "}
                Yearly License
              </code>
            </p>
            <ul className="ml-0">
              <li>
                <button
                  onClick={e =>
                    stripeCheckout(e, "price_1HuOGQJU3pXRkN5SZTxvOdWx")
                  }
                  className="mx-auto shadow px-2 py-1 w-full border border-transparent text-base leading-6 rounded text-white bg-dark-green hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2"
                >
                  Buy Now
                </button>
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 mr-1"
                  stroke="green"
                  fill="green"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
                Upload CSV Files
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 mr-1"
                  stroke="green"
                  fill="green"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
                Table View of Your Files
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 mr-1"
                  stroke="green"
                  fill="green"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
                Basic Charts (line,bar and scatter)
              </li>{" "}
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 mr-1"
                  stroke="green"
                  fill="green"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
                Basic Chart Aggregation (max,min,avg,count)
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 mr-1"
                  stroke="green"
                  fill="green"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
                Up to 10 cells (with 10 different datasets)
              </li>
              <h3 className="text-left text-sm pt-4 pb-1 mb-1">Future</h3>
              <li className="flex items-center bg-gray-400 opacity-50 pl-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  className="w-3 h-3 mr-1"
                >
                  <path d="M3 18a7 7 0 0 1 4-6.33V8.33A7 7 0 0 1 3 2H1V0h18v2h-2a7 7 0 0 1-4 6.33v3.34A7 7 0 0 1 17 18h2v2H1v-2h2zM5 2a5 5 0 0 0 4 4.9V10h2V6.9A5 5 0 0 0 15 2H5z" />
                </svg>
                Basic Data Transformation
              </li>
              <li className="flex items-center bg-gray-400 opacity-50 pl-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  className="w-3 h-3 mr-1"
                >
                  <path d="M3 18a7 7 0 0 1 4-6.33V8.33A7 7 0 0 1 3 2H1V0h18v2h-2a7 7 0 0 1-4 6.33v3.34A7 7 0 0 1 17 18h2v2H1v-2h2zM5 2a5 5 0 0 0 4 4.9V10h2V6.9A5 5 0 0 0 15 2H5z" />
                </svg>
                Additional Charts (pie, area)
              </li>
              <li className="flex items-center bg-gray-400 opacity-50 pl-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  className="w-3 h-3 mr-1"
                >
                  <path d="M3 18a7 7 0 0 1 4-6.33V8.33A7 7 0 0 1 3 2H1V0h18v2h-2a7 7 0 0 1-4 6.33v3.34A7 7 0 0 1 17 18h2v2H1v-2h2zM5 2a5 5 0 0 0 4 4.9V10h2V6.9A5 5 0 0 0 15 2H5z" />
                </svg>
                Much More...
              </li>
            </ul>
          </li>
          <li className="mx-2 px-4">
            <h2 className="text-center mb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2 inline"
                viewBox="0 0 24 24"
                fill="black"
              >
                <path d="M7 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1c2.15 0 4.2.4 6.1 1.09L12 16h-1.25L10 20H4l-.75-4H2L.9 10.09A17.93 17.93 0 0 1 7 9zm8.31.17c1.32.18 2.59.48 3.8.92L18 16h-1.25L16 20h-3.96l.37-2h1.25l1.65-8.83zM13 0a4 4 0 1 1-1.33 7.76 5.96 5.96 0 0 0 0-7.52C12.1.1 12.53 0 13 0z" />
              </svg>
              Stakeholder Pack
            </h2>
            <p className="text-sm py-4 h-28">
              Become a stakeholder and influence how explot will grow. Gain
              access to all current and future features for one year.
            </p>
            <p className="font-normal text-2xl text-center border-b-2 border-t-2 py-2">
              $ 120
              <code className="font-thin text-sm color-gray-400">
                Yearly License
              </code>
            </p>
            <ul>
              <li>
                <button
                  onClick={e =>
                    stripeCheckout(e, "price_1HuOGYJU3pXRkN5S2gqTsc16")
                  }
                  className="mx-auto shadow px-2 py-1 w-full border border-transparent text-base leading-6 rounded text-white bg-dark-green hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2"
                >
                  Buy Now
                </button>
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 mr-1"
                  stroke="green"
                  fill="green"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
                Upload CSV Files
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 mr-1"
                  stroke="green"
                  fill="green"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
                Table View of Your Files
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 mr-1"
                  stroke="green"
                  fill="green"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
                Basic Charts (line,bar and scatter)
              </li>{" "}
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 mr-1"
                  stroke="green"
                  fill="green"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
                Basic Chart Aggregation (max,min,avg,count)
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 mr-1"
                  stroke="green"
                  fill="green"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
                Up to 10 cells (with 10 different datasets)
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 mr-1"
                  stroke="green"
                  fill="green"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
                Roadmap & Backlog Access
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 mr-1"
                  stroke="green"
                  fill="green"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
                Customer Support & Help
              </li>
              <h3 className="text-let text-sm pt-4 pb-1 mb-1">Future</h3>
              <li className="flex items-center bg-gray-400 opacity-50 pl-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  className="w-3 h-3 mr-1"
                >
                  <path d="M3 18a7 7 0 0 1 4-6.33V8.33A7 7 0 0 1 3 2H1V0h18v2h-2a7 7 0 0 1-4 6.33v3.34A7 7 0 0 1 17 18h2v2H1v-2h2zM5 2a5 5 0 0 0 4 4.9V10h2V6.9A5 5 0 0 0 15 2H5z" />
                </svg>
                Basic Data Transformation
              </li>
              <li className="flex items-center bg-gray-400 opacity-50 pl-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  className="w-3 h-3 mr-1"
                >
                  <path d="M3 18a7 7 0 0 1 4-6.33V8.33A7 7 0 0 1 3 2H1V0h18v2h-2a7 7 0 0 1-4 6.33v3.34A7 7 0 0 1 17 18h2v2H1v-2h2zM5 2a5 5 0 0 0 4 4.9V10h2V6.9A5 5 0 0 0 15 2H5z" />
                </svg>
                More Charts (pie, area)
              </li>
              <li className="flex items-center bg-gray-400 opacity-50 pl-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  className="w-3 h-3 mr-1"
                >
                  <path d="M3 18a7 7 0 0 1 4-6.33V8.33A7 7 0 0 1 3 2H1V0h18v2h-2a7 7 0 0 1-4 6.33v3.34A7 7 0 0 1 17 18h2v2H1v-2h2zM5 2a5 5 0 0 0 4 4.9V10h2V6.9A5 5 0 0 0 15 2H5z" />
                </svg>
                Feature Requests
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  )
}

const EarlyAccessOld = () => {
  return (
    <div className="w-full h-screen gradient md:p-16">
      <h1 className="text-center text-white">Get early access</h1>
      <p className="text-center text-white">
        Buy explot and start exploring your data.
      </p>
      <form
        method="post"
        name="buy-eary-access-step-1"
        className="w-4/5 md:w-2/3  max-w-lg mx-auto shadow-custom p-8 rounded bg-white"
        action="/checkout/"
      >
        <input
          type="hidden"
          className="hidden"
          name="form-name"
          value="buy-eary-access-step-1"
        />
        <label className="md:w-2/3 block text-gray-500 font-bold mr-3 px-2 mb-8">
          <input
            className="mr-2 leading-tight"
            name="buy-early-access"
            type="checkbox"
          />
          <span className="text-sm">
            Buy early access to explot for a one time payment of $49.
          </span>
        </label>
        <Link
          to="/checkout/"
          className="py-2 flex-shrink-0 bg-dark-green hover:shadow-custom border-dark-green hover:text-black text-sm border-4 text-white py-1 px-2 rounded"
        >
          Continue to checkout
        </Link>
        <Link
          to="/"
          className="flex-shrink-0 border-transparent border-4 text-green text-sm py-1 px-2 rounded"
        >
          Cancel
        </Link>
      </form>
    </div>
  )
}

export default EarlyAccess
